<template>
    <section class="users container-fluid p-3">
        <div class="position-sticky title d-flex mb-3">
            <div class="d-flex d-xl-none align-items-center">
                <button-toggle-side-bar></button-toggle-side-bar>
            </div>
            <h1 class="display-4 font-weight-bolder col pl-xl-0 mb-0">
                Accounting for Activities
            </h1>
        </div>
        <div class="row">
            <div class="col-md-6">
                <ul class="list-group shadow-sm rounded-xl mb-3">
                    <li class="position-sticky list-group-item d-flex justify-content-between bg-light pl-3 pr-1 py-1">
                        <div class="font-weight-bolder">
                            Provisional Balance Sheet <br>for the period 1/4/2022-31/3/2023
                        </div>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span class="font-weight-bolder">Assets</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span>Non-current Assets</span>
                        <b-form-input size="sm"
                                      class="bg-light rounded-pill"
                                      style="width: 100px; ">
                        </b-form-input>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span>Cash and Cash Equivalent</span>
                        <b-form-input size="sm"
                                      class="bg-light rounded-pill"
                                      style="width: 100px; ">
                        </b-form-input>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span class="font-weight-bolder">Liabilities</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span>Long-term Liabilities</span>
                        <b-form-input size="sm"
                                      class="bg-light rounded-pill"
                                      style="width: 100px; ">
                        </b-form-input>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span>Current Liabilities</span>
                        <b-form-input size="sm"
                                      class="bg-light rounded-pill"
                                      style="width: 100px; ">
                        </b-form-input>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span class="font-weight-bolder">Capital</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span>Ordinary Shares</span>
                        <b-form-input size="sm"
                                      class="bg-light rounded-pill"
                                      style="width: 100px; ">
                        </b-form-input>
                    </li>

                    <li class="list-group-item d-flex justify-content-center bg-light py-1">
                        <button class="btn btn-sm btn-success rounded-pill shadow-sm px-4">
                            ⚙️ Generate
                        </button>
                    </li>
                </ul>
            </div>
            <div class="col-6">
                <ul class="list-group shadow-sm rounded-xl mb-3">
                    <li class="position-sticky list-group-item d-flex justify-content-between bg-light pl-3 pr-1 py-1">
                        <div class="font-weight-bolder">
                            Provisional Income Statement / Profit & Loss Statement<br> for the period 1/4/2022-31/3/2023
                        </div>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span>Sales</span>
                        <span><small>HK$</small>13000</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span>Cost of Goods Sold</span>
                        <span>(<small>HK$</small>5000)</span>
                    </li>
                    <li class="list-group-item font-weight-bolder d-flex justify-content-between align-items-center pl-3 p-2">
                        <span>Gross Profit</span>
                        <span><small>HK$</small>7000</span>
                    </li>
                    <li class="list-group-item font-weight-bolder pl-3 p-2">
                        Operating Expenses
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                        <span>Marketing Misc</span>
                        <span>(<small>HK$</small>3000)</span>
                    </li>
                    <li class="list-group-item font-weight-bolder d-flex justify-content-between align-items-center pl-3 p-2">
                        <span>  Net Income from Operations</span>
                        <span><small>HK$</small>4000</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-center bg-light py-1">
                        <button class="btn btn-sm btn-success rounded-pill shadow-sm px-4">
                            ⚙️ Generate
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import { BFormInput, } from "bootstrap-vue";
export default {
    name: "Accounting",
    metaInfo () {
        return {
            "title": "Accounting",
        };
    },
    components: {
        BFormInput,
        ButtonToggleSideBar: () => import("@/components/layout/ButtonToggleSideBar"),
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
