var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"users container-fluid p-3"},[_c('div',{staticClass:"position-sticky title d-flex mb-3"},[_c('div',{staticClass:"d-flex d-xl-none align-items-center"},[_c('button-toggle-side-bar')],1),_c('h1',{staticClass:"display-4 font-weight-bolder col pl-xl-0 mb-0"},[_vm._v(" Accounting for Activities ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ul',{staticClass:"list-group shadow-sm rounded-xl mb-3"},[_vm._m(0),_vm._m(1),_c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',[_vm._v("Non-current Assets")]),_c('b-form-input',{staticClass:"bg-light rounded-pill",staticStyle:{"width":"100px"},attrs:{"size":"sm"}})],1),_c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',[_vm._v("Cash and Cash Equivalent")]),_c('b-form-input',{staticClass:"bg-light rounded-pill",staticStyle:{"width":"100px"},attrs:{"size":"sm"}})],1),_vm._m(2),_c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',[_vm._v("Long-term Liabilities")]),_c('b-form-input',{staticClass:"bg-light rounded-pill",staticStyle:{"width":"100px"},attrs:{"size":"sm"}})],1),_c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',[_vm._v("Current Liabilities")]),_c('b-form-input',{staticClass:"bg-light rounded-pill",staticStyle:{"width":"100px"},attrs:{"size":"sm"}})],1),_vm._m(3),_c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',[_vm._v("Ordinary Shares")]),_c('b-form-input',{staticClass:"bg-light rounded-pill",staticStyle:{"width":"100px"},attrs:{"size":"sm"}})],1),_vm._m(4)])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"position-sticky list-group-item d-flex justify-content-between bg-light pl-3 pr-1 py-1"},[_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" Provisional Balance Sheet "),_c('br'),_vm._v("for the period 1/4/2022-31/3/2023 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Assets")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Liabilities")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Capital")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-group-item d-flex justify-content-center bg-light py-1"},[_c('button',{staticClass:"btn btn-sm btn-success rounded-pill shadow-sm px-4"},[_vm._v(" ⚙️ Generate ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-6"},[_c('ul',{staticClass:"list-group shadow-sm rounded-xl mb-3"},[_c('li',{staticClass:"position-sticky list-group-item d-flex justify-content-between bg-light pl-3 pr-1 py-1"},[_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" Provisional Income Statement / Profit & Loss Statement"),_c('br'),_vm._v(" for the period 1/4/2022-31/3/2023 ")])]),_c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',[_vm._v("Sales")]),_c('span',[_c('small',[_vm._v("HK$")]),_vm._v("13000")])]),_c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',[_vm._v("Cost of Goods Sold")]),_c('span',[_vm._v("("),_c('small',[_vm._v("HK$")]),_vm._v("5000)")])]),_c('li',{staticClass:"list-group-item font-weight-bolder d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',[_vm._v("Gross Profit")]),_c('span',[_c('small',[_vm._v("HK$")]),_vm._v("7000")])]),_c('li',{staticClass:"list-group-item font-weight-bolder pl-3 p-2"},[_vm._v(" Operating Expenses ")]),_c('li',{staticClass:"list-group-item d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',[_vm._v("Marketing Misc")]),_c('span',[_vm._v("("),_c('small',[_vm._v("HK$")]),_vm._v("3000)")])]),_c('li',{staticClass:"list-group-item font-weight-bolder d-flex justify-content-between align-items-center pl-3 p-2"},[_c('span',[_vm._v(" Net Income from Operations")]),_c('span',[_c('small',[_vm._v("HK$")]),_vm._v("4000")])]),_c('li',{staticClass:"list-group-item d-flex justify-content-center bg-light py-1"},[_c('button',{staticClass:"btn btn-sm btn-success rounded-pill shadow-sm px-4"},[_vm._v(" ⚙️ Generate ")])])])])
}]

export { render, staticRenderFns }